@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

.fz-toast {
  .Toastify__toast {
    &--success {
      background-color: #22B66E;
    }

    &--error {
      background-color: #F75060;
    }

    &--warning {
      background-color: #F19422;
    }

    &--info {
      background-color: #34B3E6;
    }
  }
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.slick-track {
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.slick-slide {
  padding: 15px;
  height: unset;

  & div:not([class]) {
    height: 100%;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #cdcdcd;
}

::-webkit-scrollbar-thumb:hover {
  background: #acacac;
}